import React, { useRef } from "react"
import EmailEditor, { EditorRef as BaseEditorRef } from "react-email-editor"

interface ExtendedEditorRef extends BaseEditorRef {
	exportHtml: (
		callback: (data: { html: string; design: object }) => void
	) => void
}

interface EmailTemplateEditorProps {
	onSave: (html: string) => void
}

const EmailTemplateEditor1: React.FC<EmailTemplateEditorProps> = ({
	onSave,
}) => {
	const editorRef = useRef<ExtendedEditorRef>(null)

	const handleSave = () => {
		return window.alert("Saved successfully!!")
		// if (editorRef.current) {
		// 	editorRef.current.exportHtml((data) => {
		// 		const { html } = data
		// 		console.log("HTML: ", html)
		// 		onSave(html)
		// 	})
		// }
	}

	return (
		<div>
			<EmailEditor ref={editorRef} />
			<button onClick={handleSave}>Save Template</button>
		</div>
	)
}

export default EmailTemplateEditor1

// import React, { useRef } from "react"
// import EmailEditor, { EditorRef } from "react-email-editor" // Adjust the import based on the library's documentation

// const EmailTemplateEditor2: React.FC = () => {
// 	const editorRef = useRef<EditorRef>(null) // Use the correct type for the ref

// 	return <EmailEditor ref={editorRef} />
// }

// export default EmailTemplateEditor2
