import React, { useState } from "react"
import EmailTemplateEditor1 from "./EmailTemplateEditor2" // Adjust the import path

const EmailTemplateEditor2Parent: React.FC = () => {
	const [savedHtml, setSavedHtml] = useState<string>("")

	const handleSaveTemplate = (html: string) => {
		// Here you would typically make an API call to save the HTML to your database.
		console.log("Saved HTML:", html)
		setSavedHtml(html)
		// Example using fetch to send to a backend API:
		fetch("/api/save-template", {
			// Replace with your actual API endpoint
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ html }),
		})
			.then((response) => {
				if (response.ok) {
					console.log("Template saved successfully")
				} else {
					console.error("error saving template")
				}
			})
			.catch((error) => {
				console.error("Error saving template:", error)
			})
	}

	return (
		<div>
			<EmailTemplateEditor1 onSave={handleSaveTemplate} />
			{/* <EmailTemplateEditor1 /> */}
			{savedHtml && (
				<div>
					<h2>Saved HTML:</h2>
					<pre>{savedHtml}</pre>
				</div>
			)}
		</div>
	)
}

export default EmailTemplateEditor2Parent
