import React, { useState } from "react"
import CodeMirror from "@uiw/react-codemirror"
import { html } from "@codemirror/lang-html"
import { liquidMixedMode } from "../utils/liquidMixedMode"
import { lineNumbers } from "@codemirror/view"
import { linter, lintGutter } from "@codemirror/lint"
import { Liquid } from "liquidjs"

const EmailTemplateEditor = () => {
	const [code, setCode] = useState(`<!DOCTYPE html>
    <html lang="en">
            {% if payment_terms.type == 'fulfillment' and payment_terms.next_payment.due_at == nil %}
            {% assign due_on_fulfillment_terms = true %}
            {% else %}
            {% assign due_on_fulfillment_terms = false %}
            {% endif %}
            <head>
				<title>{{ email_title }}</title>
				<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
				<!-- More template content -->
            </head>
            <body>
				<h1>Invoice for Order #1234</h1>
				<p>Dear {{ customer_name }},</p>
				<p>Here is the invoice for your recent order:</p>
				<table style="border-collapse: collapse; width: 100%;">
					<thead>
						<tr>
							<th style="border: 1px solid #ddd; padding: 8px;">Product</th>
							<th style="border: 1px solid #ddd; padding: 8px;">Quantity</th>
							<th style="border: 1px solid #ddd; padding: 8px;">Price</th>
						</tr>
					</thead>
					<tbody>
						{% for item in order_items %}
						<tr>
							<td style="border: 1px solid #ddd; padding: 8px;">{{ item.product_name }}</td>
							<td style="border: 1px solid #ddd; padding: 8px;">{{ item.quantity }}</td>
							<td style="border: 1px solid #ddd; padding: 8px;">{{ item.price }}</td>
						</tr>
						{% endfor %}
					</tbody>
				</table>
            </body>
    </html>`)

	// Sample data for replacing Liquid variables, including order_items
	const templateData = {
		payment_terms: {
			type: "fulfillment",
			next_payment: { due_at: null },
		},
		email_title: "Invoice for Order #1234",
		customer_name: "John Doe",
		order_items: [
			{
				product_name: "Widget A",
				quantity: 2,
				price: "$10.00",
			},
			{
				product_name: "Widget B",
				quantity: 1,
				price: "$15.00",
			},
			{
				product_name: "Widget C",
				quantity: 3,
				price: "$5.00",
			},
		],
	}

	const onChange = React.useCallback((value: any) => {
		setCode(value)
	}, [])

	const insertTemplate = (template: any) => {
		// Logic to insert template at cursor position
		// This would need to use CodeMirror's API
	}

	const saveTemplate = () => {
		// API call to save the template
		fetch("/api/email-templates/save", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ template: code }),
		})
			.then((response) => response.json())
			.then((data) => {
				// Handle success
			})
			.catch((error) => {
				// Handle error
			})
	}

	const previewTemplate = async () => {
		const engine = new Liquid()

		// Get the subject from the input field
		const subjectInput = document.querySelector(
			".subject-input"
		) as HTMLInputElement
		const subject = subjectInput ? subjectInput.value : ""

		// Open new window
		const previewWindow = window.open("", "_blank")

		if (!previewWindow) {
			alert("Popup blocked! Please allow popups for this site.")
			return
		}

		try {
			// Process the template
			const processedHtml = await engine.parseAndRender(
				code,
				templateData
			)

			// Set the title of the preview window to the subject
			previewWindow.document.title = subject

			// Ensure document writing works
			previewWindow.document.open()
			previewWindow.document.write(processedHtml)
			previewWindow.document.close()
		} catch (error) {
			console.error("Error rendering template:", error)
			previewWindow.document.write("<p>Error rendering the preview.</p>")
			previewWindow.document.close()
		}
	}
	return (
		<div className="email-editor-container">
			<div className="editor-toolbar">
				<button
					onClick={() =>
						insertTemplate("{% if condition %}...{% endif %}")
					}
				>
					Insert If Condition
				</button>
				<button onClick={() => insertTemplate("{{ variable }}")}>
					Insert Variable
				</button>
				{/* More toolbar buttons */}
			</div>

			{/* <h2>Edit Order invoice</h2> */}
			<div className="editor-section">
				<h3>Email subject</h3>
				<input
					type="text"
					className="subject-input"
					defaultValue={templateData.email_title}
				/>
			</div>
			<div className="editor-section">
				<h3>Email body (HTML)</h3>
				<CodeMirror
					value={code}
					height="400px"
					extensions={[
						liquidMixedMode(),
						lineNumbers(),
						lintGutter(),
					]}
					onChange={onChange}
					theme="dark"
				/>
			</div>
			<div className="editor-actions">
				<button className="secondary-button">Revert to default</button>
				<button className="primary-button" onClick={previewTemplate}>
					Preview
				</button>
			</div>
		</div>
	)
}

export default EmailTemplateEditor
