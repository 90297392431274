import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { PageContainer } from "../../../../../components/app-frame-elements"
import CommonSnackbarComponent from "../../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import EmailTemplateSetNavController from "./sub-components/EmailTemplateSetNavController"
import EmailTemplateItems from "./sub-components/EmailTemplateItems"
import CreateEmailTemplateSetDialog from "./sub-components/dialogs/CreateEmailTemplateSetDialog"
import EditEmailTemplateSetDialog from "./sub-components/dialogs/EditEmailTemplateSetDialog"
import { Button } from "@material-ui/core"
import EmailTemplateEditor from "./sub-components/EmailTemplateEditor"
import EmailTemplateEditor2 from "./sub-components/email-template-2/EmailTemplateEditor2"
import styled from "styled-components"
import EmailTemplateEditor2Parent from "./sub-components/email-template-2/EmailTemplate2Parent"

export default observer(function EmailTemplate({
	partialStore,
	fetchingStatus,
	permission,
	contentHeight,
}: {
	partialStore: any
	fetchingStatus: ActionStatus
	permission: PermissionAsObjectProps
	contentHeight: any
}) {
	const [selectedTemplate, setSelectedTemplateSet] = useState<any>(null)
	const [activeTab, SetActiveTab] = useState<number>(1)
	return (
		<PageContainer
			hasToolbar={false}
			fullWidth
			fixedHeight
			isLoading={fetchingStatus === "LOADING"}
		>
			{fetchingStatus === "SUCCESS" && (
				// <div
				// 	className="FR proj-arch-date-policy"
				// 	style={{ height: contentHeight }}
				// >
				// 	<EmailTemplateSetNavController
				// 		permission={permission}
				// 		setSelectedTemplateSet={setSelectedTemplateSet}
				// 	/>
				// 	<EmailTemplateItems
				// 		partialStore={partialStore}
				// 		selectedTemplate={selectedTemplate}
				// 	/>
				// </div>

				<StylesEmailTemplate>
					<div className="button-container">
						<Button
							variant="contained"
							color="default"
							onClick={() => {
								SetActiveTab(1)
							}}
							className={activeTab === 1 ? "active" : ""}
						>
							Template 1
						</Button>
						<Button
							variant="contained"
							color="default"
							onClick={() => {
								SetActiveTab(2)
							}}
							className={activeTab === 2 ? "active" : ""}
						>
							Template 2
						</Button>
					</div>

					{activeTab === 1 && <EmailTemplateEditor />}
					{activeTab === 2 && <EmailTemplateEditor2Parent />}
				</StylesEmailTemplate>
			)}
			<CommonSnackbarComponent i18n={{}} partialStore={partialStore} />
			{partialStore.openCreateDialog && <CreateEmailTemplateSetDialog />}
			{partialStore.openEditDialog && <EditEmailTemplateSetDialog />}
		</PageContainer>
	)
})

const StylesEmailTemplate = styled.div`
	.active {
		background-color: #3f51b5 !important;
		color: white !important;
	}
	.button-container {
		display: flex;

		margin-top: 1rem;
	}
	.button-container button {
		margin-right: 1rem;
	}
`
